<template>
    <ion-row class="full-height pad-bottom-thirty">
      <ion-col class="margin-bottom-eighty">
        <h3 class="text-center">Task Assignments</h3>
        <div class="prime-form-container">
          <p>For each task type, select which user you would like to have tasks automatically assigned to. By default, tasks will be assigned to a patient's full care team.</p>
          <form novalidate="true">
            <div class="prime-form-group" v-for="task in tasks" :key="task.id">
              <h6>{{ task.taskingTypeName }}</h6>
              <select class="form-control" @change="modifyTaskAssignments(task.taskingTypeId, $event)">
                <option value="0" class="text-grey">Patient Care Team Default</option>
                <option v-for="prof in professionals" :value="prof.professionalId" :key="prof.professionalId + task.taskingTypeName" :selected="task.professionalId ? task.professionalId === prof.professionalId : 0">
                  {{ (prof.title ? prof.title + " " : "") + prof.name }}
                </option>
              </select>
            </div>
            <button @click="upsertTasks" type="button" class="prime-button button-primary button-block margin-top-thirty">Save Updates</button>
          </form>
        </div>
      </ion-col>
    </ion-row>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import PrimePage from "@/components/Global/PrimePage";

export default {
  name: "TaskAssignments",
  components: {
    PrimePage
  },
  data() {
    return {
      tasks: [],
      professionals: [],
      taskAssignments: {},
      pageIsLoading: true,
      errors: []
    };
  },
  async created() {
    await Promise.allSettled([await this.fetchOrganizationTasks(), this.fetchOrganizationProfessionals()]).then(res => {
      this.tasks = res[0].value.data.filter(tsk => tsk.isManageable);
      this.professionals = res[1].value.data;
      this.tasks.forEach(task => {
        let taskObj = {
          [task["taskingTypeId"]]: task["professionalId"]
        };
        this.taskAssignments = { ...this.taskAssignments, ...taskObj };
      });
      this.pageIsLoading = false;
    });
  },
  methods: {
    async fetchOrganizationTasks() {
      this.pageIsLoading = true;
      const method = "get";
      const path = document.config.taskAssignment + this.$route.params.id;

      try {
        const resp = await httpSend({ path, method, authToken: true });
        return resp;
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Failed to load organization tasks",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    },
    async fetchOrganizationProfessionals() {
      this.pageIsLoading = true;
      const method = "get";
      const path = document.config.organization + this.$route.params.id + "/professionals";

      try {
        const resp = await httpSend({ path, method, authToken: true });
        return resp;
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Failed to load organization professionals",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    },
    modifyTaskAssignments(task, e) {
      let newObj = {
        [task]: e.target.value
      };
      this.taskAssignments = { ...this.taskAssignments, ...newObj };
    },
    async upsertTasks() {
      const method = "post";
      const path = document.config.taskAssignment + this.$route.params.id;
      const payload = this.taskAssignments;

      try {
        const resp = await httpSend({ path, method, payload, authToken: true });
        this.$ionic.toastController
          .create({
            header: "Success!",
            message: "Tasks have been updated",
            duration: 4000,
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());
        return resp;
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Failed to update tasks",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        khanSolo.log(error);
      }
    }
  }
};
</script>

<style scoped>
form {
  margin-bottom: 2rem;
}

.margin-bottom-eighty {
  margin-bottom: 80px;
}
</style>
